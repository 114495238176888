import { parseWithZod } from "@conform-to/zod";
import { type ClassValue, clsx } from "clsx";
import { toast } from "sonner";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

export function cn(...inputs: Array<ClassValue>) {
  return twMerge(clsx(inputs));
}

export function singleton<Value>(name: string, value: () => Value): Value {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const g = global as any;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  g.__singletons ??= {};
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  g.__singletons[name] ??= value();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
  return g.__singletons[name];
}

export async function parseFormData<T extends z.ZodTypeAny>(args: { request: Request; schema: T }) {
  const formData = await args.request.formData();
  const result = args.schema.safeParse(Object.fromEntries(formData));
  return result as z.SafeParseReturnType<FormData, z.infer<T>>;
}

export async function parseConformSubmission<T extends z.ZodTypeAny>(args: { request: Request; schema: T }) {
  const formData = await args.request.formData();
  const submission = parseWithZod<T>(formData, { schema: args.schema });
  return submission;
}

export function supportsNotifications() {
  return "Notification" in window;
}

export function getNotificationPermission() {
  if (typeof window === "undefined") {
    return "denied";
  }

  if (!("Notification" in window)) {
    return "denied";
  }

  return Notification.permission;
}

export function askNotificationPermission() {
  if (typeof window === "undefined") {
    return;
  }

  if (!("Notification" in window)) {
    console.warn("This browser does not support notifications.");
    return;
  }

  if (Notification.permission === "denied") {
    toast.warning("You have blocked notifications", {
      description: "Please enable notifications in your browser settings.",
    });
  }
  Notification.requestPermission().catch((err) => console.error(err));
}

export function capitalize(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

export function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const debounce = <T extends (...args: Array<any>) => any>(callback: T, wait: number) => {
  let timeoutId: number | null = null;

  return (...args: Parameters<T>): void => {
    if (timeoutId !== null) {
      window.clearTimeout(timeoutId);
    }

    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  };
};
